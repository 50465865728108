<template>
  <travio-center-container grid-width="1/2" :pageTitle="pageTitle">
    <vx-card>
      <div class="vx-row mb-6" v-if="voucherCodeId > 0">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Id</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" :disabled="true" v-model.number="form.id" name="Id" />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Promo Code*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.code" v-validate="'required|max:50'" name="Code" :disabled="readOnly" />
          <span class="text-danger text-sm">{{ errors.first('Code') }}</span>
        </div>
      </div>
     
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.name" name="Name"  v-validate="'max:250'" :disabled="readOnly" />
          <span class="text-danger text-sm">{{ errors.first('Name') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Amount* (add - for discount)</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model.number="form.value" name="Value" type="number" v-validate="'required'" :disabled="readOnly" />
          <span class="text-danger text-sm">{{ errors.first('Value') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Is Percent</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full ml-auto">
          <!-- <vs-checkbox class="inline-flex" v-model="dummy2">Enable 2FA</vs-checkbox> -->
          <vs-switch class="" v-model="form.isPct" :disabled="readOnly" />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Max Uses</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model.number="form.maxUses" name="Max Uses" type="number" v-validate="'required'" :disabled="readOnly" />
          <span class="text-danger text-sm">{{ errors.first('Max Uses') }}</span>
        </div>
      </div>


      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Expiry Date</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <flat-pickr v-model="form.expiryDate" :config="{ dateFormat: 'Y-m-d' }" class="w-full" name="Expiry Date" :disabled="readOnly" />
          <span class="text-danger text-sm">{{ errors.first('Expiry Date') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6" v-if="voucherCodeId > 0">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Uses</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model.number="form.uses" name="Max Uses" type="number" disabled />
          <span class="text-danger text-sm">{{ errors.first('Uses') }}</span>
        </div>
      </div>

      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" color="danger" class="mr-4" >Cancel</vs-button>
        <template v-if="!readOnly">
          <vs-button v-if="voucherCodeId == 0" @click='handleAdd' class="">Save</vs-button>
          <vs-button v-if="voucherCodeId > 0" @click='handleEdit' class="">Save</vs-button>
        </template>
      </div>

    </vx-card>
  </travio-center-container>

</template>

<script>
import vSelect from 'vue-select'
import { Form } from '@/core-modules/form-framework/Form.js'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  components: {
    vSelect,
    flatPickr
  },
  props: {
    applicationId: { required: true},
    voucherRuleId: { required: true },
    voucherCodeId: { required: false, default: 0 },
    readOnly: { type: Boolean, default: false },
  },
  data () {
    return {
      form: new Form({
        id: 0,
        name: '',
        code: '',
        value: 0.0,
        isPct: false,
        expiryDate: null,
        maxUses: 0,
        uses: 0,
        voucherRuleId: 0
      })
    }
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    pageTitle () {
      return this.voucherCodeId > 0
        ? 'Edit Voucher Code for Voucher Rule Id ' + this.voucherRuleId
        : 'New Voucher Code for Voucher Rule Id ' + this.voucherRuleId
    }
  },
  mounted () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    this.form.voucherRuleId = parseInt(this.voucherRuleId)
    if( this.voucherCodeId > 0) {
      this.$vs.loading()
      this.$http
      .get(`api/vouchercodes/apps/${this.applicationId}/${this.voucherCodeId}`)
      .then(response => { 
        this.form = new Form(Object.assign(this.form.data(), response.data))
      })
      .catch(err => console.error(err) )
      .finally(() => this.$vs.loading.close())

    }
  },
  methods: {
    handleAdd () {
      // Date form control sets value to "" when touched, revert back to null to compty with API
      if( this.form.expiryDate === "" ) {
        const  newFormData = Object.assign(this.form.data(), { expiryDate: null })
        this.form = new Form(newFormData)
      }
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.form.post(`api/vouchercodes/apps/${this.applicationId}`)
            .then(response => {
              this.$router.push({ name: 'application-vouchercodes', 
                params: { 
                  applicationId: this.applicationId,
                  voucherRuleId: this.voucherRuleId
                }
              })
              this.$_notifySuccess('Voucher code successfully added');
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    handleEdit() {
      // Date form control sets value to "" when touched, revert back to null to comply with API
      if( this.form.expiryDate === "" ) {
        const  newFormData = Object.assign(this.form.data(), { expiryDate: null })
        this.form = new Form(newFormData)
      }
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.form.put(`api/vouchercodes/apps/${this.applicationId}`)
            .then(response => {
              this.$router.push({ name: 'application-vouchercodes', 
                params: { 
                  applicationId: this.applicationId,
                  voucherRuleId: this.voucherRuleId
                }
              })
              this.$_notifySuccess('Voucher code successfully updated');
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    handleCancel () {
      this.$router.push({ name: 'application-vouchercodes', 
        params: { 
          applicationId: this.applicationId,
          voucherRuleId: this.voucherRuleId
        }
      })
    }
  }
}
</script>
