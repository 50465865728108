var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2", pageTitle: _vm.pageTitle } },
    [
      _c("vx-card", [
        _vm.voucherCodeId > 0
          ? _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                _c("span", [_vm._v("Id")]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col sm:w-4/5 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { disabled: true, name: "Id" },
                    model: {
                      value: _vm.form.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "id", _vm._n($$v))
                      },
                      expression: "form.id",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Promo Code*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:50",
                    expression: "'required|max:50'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Code", disabled: _vm.readOnly },
                model: {
                  value: _vm.form.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "code", $$v)
                  },
                  expression: "form.code",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Code"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Name*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:250",
                    expression: "'max:250'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Name", disabled: _vm.readOnly },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Amount* (add - for discount)")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  name: "Value",
                  type: "number",
                  disabled: _vm.readOnly,
                },
                model: {
                  value: _vm.form.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "value", _vm._n($$v))
                  },
                  expression: "form.value",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Value"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Is Percent")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full ml-auto" },
            [
              _c("vs-switch", {
                attrs: { disabled: _vm.readOnly },
                model: {
                  value: _vm.form.isPct,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isPct", $$v)
                  },
                  expression: "form.isPct",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Max Uses")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  name: "Max Uses",
                  type: "number",
                  disabled: _vm.readOnly,
                },
                model: {
                  value: _vm.form.maxUses,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "maxUses", _vm._n($$v))
                  },
                  expression: "form.maxUses",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Max Uses"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Expiry Date")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("flat-pickr", {
                staticClass: "w-full",
                attrs: {
                  config: { dateFormat: "Y-m-d" },
                  name: "Expiry Date",
                  disabled: _vm.readOnly,
                },
                model: {
                  value: _vm.form.expiryDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "expiryDate", $$v)
                  },
                  expression: "form.expiryDate",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Expiry Date"))),
              ]),
            ],
            1
          ),
        ]),
        _vm.voucherCodeId > 0
          ? _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                _c("span", [_vm._v("Uses")]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col sm:w-4/5 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { name: "Max Uses", type: "number", disabled: "" },
                    model: {
                      value: _vm.form.uses,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "uses", _vm._n($$v))
                      },
                      expression: "form.uses",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("Uses"))),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "lg:float-left mt-4" }, [
          _c("span", { staticClass: "text-sm text-danger" }, [
            _vm._v("*Required Field"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            _c(
              "vs-button",
              {
                staticClass: "mr-4",
                attrs: { color: "danger" },
                on: { click: _vm.handleCancel },
              },
              [_vm._v("Cancel")]
            ),
            !_vm.readOnly
              ? [
                  _vm.voucherCodeId == 0
                    ? _c("vs-button", { on: { click: _vm.handleAdd } }, [
                        _vm._v("Save"),
                      ])
                    : _vm._e(),
                  _vm.voucherCodeId > 0
                    ? _c("vs-button", { on: { click: _vm.handleEdit } }, [
                        _vm._v("Save"),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }